@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4
  }

  button {
    @apply text-white border bg-green-700 border-white rounded-full
    hover:bg-green-100 hover:text-green-700 hover:border-green-700 transition duration-300 ease-in-out 
  }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');


body {
  @apply bg-green-50
  overflow-x-hidden
}